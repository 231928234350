<template>
	<router-link :to="{ name: 'ResourcesCategory', params: { id: id } }" class="cat-tag">
		<TagIcon class="tag-icon" />
		<span>{{ title }}</span>
	</router-link><!-- card -->
</template>

<script>
import TagIcon from '@/assets/tag.svg';
export default {
	name: 'CatTag',
	props: {
        id: String,
		title: String
	},
	components: {
		TagIcon
	}
};
</script>

<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.cat-tag {
		border-radius: 21px;
		background: $snow;
		display: flex;
		align-items: center;
		flex-shrink: 0;
		height: 38px;
		padding: 0 15px;
		margin-right: 8px;
		.tag-icon {
		    width: 16px;
			overflow: visible;
			margin-right: 5px;
			.line {
				stroke: $slate;
				stroke-width: 5;
			}
			.dot {
				fill: $slate;
			}
		}
		span {
			font-size: 13px;
			line-height: 16px;
			font-weight: $weight_600;
			color: $slate;
		}
		&:last-of-type {
			margin-right: 0;
		}
	}
</style>
