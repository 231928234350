<template>
	<div class="resources">
		<Nav />
		<span class="overlay" :class="{ active: isMenuOpen || isResourceSearchModalOpen || isResourceAdviceModalOpen || isAccessModalOpen }"></span>
		<div class="header header-standard">
			<HeaderNavBar back search searchType="resources"/>
		</div><!-- header -->
		<div class="header-image red"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="FolderIcon"
				title="Well-being"
				colour="red"
			/>
			<div class="section-gap card-wrap">
				<Card
					class="card-block-red"
					icon="BookIcon"
					category="Advice"
					title="Seek advice"
					text="Contact us"
                    @click.native="$store.commit('RESOURCE_ADVICE_TOGGLE');"
				/>
				<Card
					routeName="ResourcesMy"
					class="card-block-red"
					icon="StarIcon"
					category="Resources"
					title="My Resources"
					text="Your favourite material"
				/>
			</div><!-- card wrap -->
			<div class="section-gap section-ports">
				<div class="section-title-wrap">
					<h2 class="section-title">Latest Resources</h2>
                    <router-link :to="{ name: 'ResourcesLatest' }" class="page-link page-link-red">View more &raquo;</router-link>
				</div>
				<div class="card-row-wrap">
					<CardRow
                        v-for="resource in latest"
                        :key="resource.id"
						routeName="ResourcesSingle"
                        :id="resource.id"
						class="card-row-block-resources"
						:title="resource.title"
						:tag="resource.category"
						:type="resource.simplified_type"
					/>
				</div><!-- port card wrap -->
			</div>
			<div class="section-gap section-cat-tags">
				<div class="section-title-wrap">
					<h2 class="section-title">Categories</h2>
				</div>
				<div class="cat-tag-wrap" v-dragscroll>
					<CatTag
                        v-for="(category, index) in categories"
                        :key="index"
                        :title="category"
                        :id="category"
                    />
				</div><!-- port card wrap -->
			</div>
			<div class="section-gap card-wrap">
				<Card
					routeName="Requests"
					class="card-block-green"
					icon="SendIcon"
					category="Requests"
					title="Make a Request"
					text="Pre-order goods and services"
				/>
				<Card
					link="https://www.seafarershappinessindex.org"
					class="card-block-sky"
					icon="ShipIcon"
					category="Happiness index"
					title="Seafarers Happiness Index"
					text="Make your views known"
				/>
			</div><!-- card wrap -->
			<div class="section-gap section-ports">
				<div class="section-title-wrap">
					<h2 class="section-title">Featured Resources</h2>
                    <router-link :to="{ name: 'ResourcesLatest' }" class="page-link page-link-red">View more &raquo;</router-link>
				</div>
				<div class="card-row-wrap-alt" v-dragscroll>
					<CardRow
                        v-for="resource in featured"
                        :key="resource.id"
						eyebrow
						routeName="ResourcesSingle"
                        :id="resource.id"
						class="card-row-block-resources card-row-block-alt"
						:title="resource.title"
						:text="resource.simplified_content"
						:tag="resource.category"
						:type="resource.simplified_type"
					/>
				</div><!-- port card wrap -->
			</div>
			<div class="section-gap section-cat-tags">
				<div class="section-title-wrap">
					<h2 class="section-title">Media Types</h2>
				</div>
				<div class="media-block-wrap" v-dragscroll>
					<MediaBlock
                        v-for="(type, index) in types"
                        :key="index"
                        :id="type"
                        :title="type"
                        :type="type"/>
				</div><!-- port card wrap -->
			</div>
			<div class="section-gap section-news">
				<div class="section-title-wrap">
					<h2 class="section-title">Latest News</h2>
					<router-link :to="{ name: 'News' }" class="page-link page-link-grey">View more &raquo;</router-link>
				</div>
				<div class="news-card-wrap" v-dragscroll>
					<NewsCard
                        v-for="article in news"
						:key="article.id"
                        :id="article.id"
						:image="article.image_url"
						:title="article.title"
						:date="article.created_at | moment('Do MMMM YYYY')"
					/>
				</div><!-- port card wrap -->
			</div>
		</div>

        <div ref="modalAccess" class="phone-modal with-nav" style="bottom: -600px;" :class="{active: isAccessModalOpen}">
            <span class="modal-line"></span>
            <div class="modal-title-bar">
                <div class="icon-wrap">
                    <RegisterIcon style="width: 27px; height: 27px;" class="icon register-icon" />
                    <span>Create your profile</span>
                </div><!-- icon wrap -->
                <GreyCloseIcon @click="closeAccessModal" style="width: 31px; height: 31px;" class="icon close" />
            </div><!-- modal title bar -->
            <div class="modal-message-block wide">
                <ProfileIcon class="profile-icon" style="width: 50px; height: 50px;" />
                <h3>Please create a profile to access this area.</h3>
                <p>This app will always be free, but creating a profile will give you greater access to the app.</p>
            </div>
            <Button routeName="/create-profile" icon="RegisterIcon" class="btn btn-green btn-icon btn-icon-white btn-icon-small btn-icon-right" text="Okay, I'll create a profile"></Button>
            <Button @click.native="openLoginScreen" icon="LoginIcon" class="btn btn-slate btn-icon btn-icon-white btn-icon-small btn-icon-right" text="I already have a profile"></Button>
            <a @click="closeAccessModal()" class="text-link text-link-grey">I'll create a profile another time &raquo;</a>
        </div><!-- modal -->

		<ResourceSearchOverlay />
		<ResourceAdviceOverlay />
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import Card from '@/components/Card.vue';
	import CardRow from '@/components/CardRow.vue';
	import CatTag from '@/components/CatTag.vue';
	import NewsCard from '@/components/NewsCard.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	import MediaBlock from '@/components/MediaBlock.vue';
	import ResourceSearchOverlay from '@/components/ResourceSearchOverlay.vue';
	import ResourceAdviceOverlay from '@/components/ResourceAdviceOverlay.vue';
    import ResourceService from '@/services/ResourceService';
    import RegisterIcon from '@/assets/register.svg';
    import ProfileIcon from '@/assets/profile.svg';
    import GreyCloseIcon from '@/assets/close-grey.svg';
    import Button from '@/components/Button.vue';
	export default {
		name: 'Resources',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			Card,
			CardRow,
			NewsCard,
			CatTag,
			SectionTitle,
			MediaBlock,
			ResourceSearchOverlay,
			ResourceAdviceOverlay,
            RegisterIcon,
            ProfileIcon,
            GreyCloseIcon,
            Button
		},
		data () {
	        return {
                latest: [],
                categories: [],
                featured: [],
                types: [],
                news: []
	        };
	    },
	    methods: {
            getResources () {
                return ResourceService.getResources().then((response) => {
                    this.latest = response.data.latest;
                    this.categories = response.data.categories;
                    this.featured = response.data.featured;
                    this.types = response.data.types;
                    this.news = response.data.news;
                });
            },
            openLoginScreen () {
                this.$router.push({ name: 'Home', params: { showLoginModal: true } });
            },
            closeAccessModal () {
                this.$store.state.isAccessModalOpen = false;
            }
        },
	    computed: {
	    	isResourceSearchModalOpen () {
	            return this.$store.state.isResourceSearchModalOpen;
	        },
	        isResourceAdviceModalOpen () {
	            return this.$store.state.isResourceAdviceModalOpen;
	        },
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        },
            isAccessModalOpen () {
                return this.$store.state.isAccessModalOpen;
            },
            isAuthenticated () {
                return this.$store.getters.isAuthenticated;
            }
		},
		mounted () {
            this.getResources();
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
</style>
