<template>
	<router-link v-if="type" :to="{ name: 'ResourcesMediaType', params: { id: type } }" class="media-block">
		<span>{{ title }}</span>
		<DocumentIcon style="width: 22px;" v-if="type === 'Document'" />
		<VideoIcon style="width: 26px;" v-if="type === 'Video'" />
		<LinkIcon style="width: 26px;" v-if="type === 'Link'" />
		<AudioIcon style="width: 26px;" v-if="type === 'Audio'" />
        <ImageIcon style="width: 26px;" v-if="type === 'Image'" />
	</router-link><!-- card -->
</template>

<script>
import DocumentIcon from '@/assets/document.svg';
import VideoIcon from '@/assets/video.svg';
import LinkIcon from '@/assets/link.svg';
import AudioIcon from '@/assets/audio.svg';
import ImageIcon from '@/assets/image-upload.svg';
export default {
	name: 'MediaBlock',
	props: {
		title: String,
		type: String
	},
	components: {
		LinkIcon,
		VideoIcon,
		DocumentIcon,
		AudioIcon,
        ImageIcon
	}
};
</script>

<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.media-block {
		position: relative;
		display: flex;
		margin-right: 14px;
		font-size: 13px;
		line-height: 17px;
		font-weight: $weight_600;
		height: 110px;
		width: 110px;
		flex-direction: column;
		background: $snow;
		border-radius: 12px;
		padding: 17px;
		justify-content: space-between;
		flex-shrink: 0;
		text-align: left;
		&:last-of-type {
			margin-right: 0;
		}
		span {
			color: $slate;
		}
		svg g, svg path {
			stroke: $slate;
		}
		svg .fill {
			fill: $slate;
			stroke: none;
		}
	}
</style>
